export const downloadBlob = (
  data: ArrayBuffer,
  contentType: string,
  contentDisposition: string,
  isB64: boolean = false
) => {
  const fileName = contentDisposition.substring(contentDisposition.indexOf("=") + 1).replaceAll('"', "");
  let blob: Blob = new Blob([data], { type: contentType });
  const urlObject = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = urlObject;
  link.download = fileName;
  document.body.appendChild(link);
  // Dispatch click event on the link
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window
    })
  );
  // Remove link from body
  document.body.removeChild(link);
};
