import { isBrowser } from "app/utils";
import { useMemo } from "react";
import { useMedia } from "react-use";

export const useHasMinScreenWitdh = (width: string) => {
  const isAWideScreen = useMedia(`(min-width: ${width}`);
  const isMobile = useMemo(() => {
    return !(isBrowser && isAWideScreen);
  }, [isAWideScreen]);

  return isMobile;
};

export const useIsNotWideScreen = () => {
  return useHasMinScreenWitdh("1366px");
  // const isAWideScreen = useMedia("(min-width: 1366px");
  // const isMobile = useMemo(() => {
  //   return !(isBrowser && isAWideScreen);
  // }, [isAWideScreen]);

  // return isMobile;
};

export const useIsMobile = () => {
  return useHasMinScreenWitdh("768px");
  // const isAWideScreen = useMedia("(min-width: 768px");
  // const isMobile = useMemo(() => {
  //   return !(isBrowser && isAWideScreen);
  // }, [isAWideScreen]);

  // return isMobile;
};
