import { ReactNode } from "react";
import { Topbar } from "./Topbar";

export const Content = ({ children }: { children: ReactNode }) => {
  return (
    <main className="main">
      <Topbar />
      <div className="wrapper">{children}</div>
    </main>
  );
};
