import { useField } from "uniforms";

export function useDefaultValueFrom(field: string): any {
  const parts = (field ?? "").split(".");
  const parentField = parts[0];
  const [{ value: parent }] = useField(parentField, {}, { absoluteName: true });

  if (!parentField || parentField === "" || !parent) return undefined;
  let value: any = parent;
  for (let i = 1; i < parts.length; i++) {
    const part: string = parts[i];
    const arrayPathAccessor = part.includes("[") && part.includes("]");
    if (arrayPathAccessor) {
      const arrayPart = part.substring(0, part.indexOf("["));
      value = value[arrayPart];
      if (Array.isArray(value)) {
        let arrayIndex = part.substring(part.indexOf("[") + 1, part.indexOf("]"));
        value = value[parseInt(arrayIndex)];
      } else {
        return undefined;
      }
    } else if (typeof value === "object" && part in value) {
      value = value[part];
    } else return undefined;
  }

  return value;
}
