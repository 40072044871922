import { Role } from "app/auth";
import { NotificationCount } from "app/componentV2/notifications";

const navigations = [
  {
    allowAccessTo: [Role.ALL],
    checkRolesStrictly: false,
    items: [
      {
        icon: "icon-icon-dashboard-white",
        label: "Dashboard",
        path: "/fields",
        allowAccessTo: [Role.ALL],
        checkRolesStrictly: false
      },
      {
        icon: "icon-icon-devices-grey",
        label: "sidebar.menus.main.devices",
        path: "/devices",
        allowAccessTo: [Role.ALL],
        checkRolesStrictly: false
      },
      {
        icon: "icon-icon-devices-grey",
        label: "sidebar.menus.main.wirelessDevices",
        path: "/wireless-devices",
        allowAccessTo: [Role.ALL],
        checkRolesStrictly: false
      },
      {
        icon: "icon-icon-my-sensors-blue",
        label: "sidebar.menus.main.sensors",
        path: "/sensors",
        allowAccessTo: [Role.ALL],
        checkRolesStrictly: false
      },
      {
        icon: "bi-droplet",
        label: "sidebar.menus.main.evapotranspiration",
        path: "/evapotranspiration",
        allowAccessTo: [Role.SUPERADMIN],
        checkRolesStrictly: true
      },
      {
        icon: "bi-bell",
        label: "sidebar.menus.main.notifications",
        path: "/notifications",
        allowAccessTo: [Role.ALL],
        checkRolesStrictly: false,
        decorateIconWith: <NotificationCount navItem />
      }
    ]
  },
  {
    label: "sidebar.menus.management.label",
    allowAccessTo: [Role.ALL],
    checkRolesStrictly: false,
    items: [
      {
        icon: "icon-icon-field-groups-blue",
        label: "sidebar.menus.definitions.groups",
        path: "/groups",
        allowAccessTo: [Role.ALL],
        checkRolesStrictly: false
      },
      {
        icon: "icon-icon-fields-green",
        label: "sidebar.menus.main.fields",
        path: "/management/fields",
        allowAccessTo: [Role.ALL],
        checkRolesStrictly: true
      },
      {
        icon: "icon-icon-agea-cultures-blue",
        label: "sidebar.menus.management.cultures",
        path: "/management/cultures",
        allowAccessTo: [Role.ALL],
        checkRolesStrictly: true
      }
    ]
  },
  {
    label: "sidebar.menus.definitions.label",
    allowAccessTo: [Role.SUPERADMIN],
    checkRolesStrictly: true,
    items: [
      {
        icon: "icon-icon-device-settings-green",
        label: "sidebar.menus.definitions.devices",
        path: "/definitions/devices",
        allowAccessTo: [Role.SUPERADMIN],
        checkRolesStrictly: true
      },
      {
        icon: "icon-icon-my-sensors-blue",
        label: "sidebar.menus.definitions.sensors",
        path: "/definitions/sensors",
        allowAccessTo: [Role.SUPERADMIN],
        checkRolesStrictly: true
      },
      {
        icon: "icon-icon-variables-blue",
        label: "sidebar.menus.definitions.variables",
        path: "/definitions/variables",
        allowAccessTo: [Role.SUPERADMIN],
        checkRolesStrictly: true
      },
      {
        icon: "bi-cpu",
        label: "sidebar.menus.main.firmwares",
        path: "/firmwares",
        allowAccessTo: [Role.SUPERADMIN],
        checkRolesStrictly: true
      },
      {
        icon: "icon-icon-agea-cultures-blue",
        label: "sidebar.menus.definitions.culture-types",
        path: "/culture-types",
        allowAccessTo: [Role.SUPERADMIN],
        checkRolesStrictly: true
      },
      {
        icon: "icon-icon-field-types-blue",
        label: "sidebar.menus.definitions.field-types",
        path: "/field-types",
        allowAccessTo: [Role.SUPERADMIN],
        checkRolesStrictly: true
      },
      {
        icon: "icon-icon-granulometries-blue",
        label: "sidebar.menus.definitions.granulometries",
        path: "/granulometries",
        allowAccessTo: [Role.SUPERADMIN],
        checkRolesStrictly: true
      },
      {
        icon: "icon-icon-irrigation-methods-blue",
        label: "sidebar.menus.definitions.irrigation-methods",
        path: "/irrigation-methods",
        allowAccessTo: [Role.SUPERADMIN],
        checkRolesStrictly: true
      },
      {
        icon: "icon-icon-irrigation-sources-blue",
        label: "sidebar.menus.definitions.irrigation-sources",
        path: "/irrigation-sources",
        allowAccessTo: [Role.SUPERADMIN],
        checkRolesStrictly: true
      },
      {
        icon: "icon-icon-coverage-types-blue",
        label: "sidebar.menus.definitions.coverage-types",
        path: "/coverage-types",
        allowAccessTo: [Role.SUPERADMIN],
        checkRolesStrictly: true
      },
      {
        icon: "icon-icon-agea-cultures-blue",
        label: "sidebar.menus.definitions.cultures",
        path: "/definitions/cultures",
        allowAccessTo: [Role.SUPERADMIN],
        checkRolesStrictly: true
      },
      {
        icon: "icon-icon-agea-cultures-blue",
        label: "sidebar.menus.definitions.varieties",
        path: "/definitions/varieties",
        allowAccessTo: [Role.SUPERADMIN],
        checkRolesStrictly: true
      }
    ]
  },
  {
    label: "sidebar.menus.administration.label",
    allowAccessTo: [Role.SUPERADMIN, Role.ADMIN, Role.MANAGER],
    checkRolesStrictly: false,
    items: [
      {
        icon: "bi-building",
        label: "sidebar.menus.administration.profile",
        path: "/profile",
        allowAccessTo: [Role.ADMIN, Role.MANAGER],
        checkRolesStrictly: false
      },
      {
        icon: "icon-icon-users-green",
        label: "sidebar.menus.administration.users",
        path: "/users",
        allowAccessTo: [Role.ADMIN],
        checkRolesStrictly: true
      },
      {
        icon: "icon-icon-tenants-green",
        label: "sidebar.menus.administration.tenants",
        path: "/tenants",
        allowAccessTo: [Role.SUPERADMIN],
        checkRolesStrictly: true
      },
      {
        icon: "bi-buildings",
        label: "sidebar.menus.administration.companies",
        path: "/companies",
        allowAccessTo: [Role.SUPERADMIN],
        checkRolesStrictly: true
      },
      {
        icon: "bi-key",
        label: "sidebar.menus.administration.api-keys",
        path: "/api-keys",
        allowAccessTo: [Role.SUPERADMIN],
        checkRolesStrictly: true
      },
      {
        icon: "bi-gear-fill",
        label: "sidebar.menus.administration.generalSettings",
        path: "/settings",
        allowAccessTo: [Role.SUPERADMIN],
        checkRolesStrictly: true
      },
      {
        icon: "bi-briefcase-fill",
        label: "sidebar.menus.administration.scheduled",
        path: "/scheduled-jobs",
        allowAccessTo: [Role.SUPERADMIN],
        checkRolesStrictly: true
      }
    ]
  }
];

export const getAllowedNavigationPaths = (hasRoles: (roles: Role[], all: boolean) => boolean) => {
  const allowedNavigations = navigations.filter((nav) => {
    return hasRoles(nav.allowAccessTo, nav.checkRolesStrictly);
  });

  return allowedNavigations.map((nav) => ({
    ...nav,
    items: nav.items.filter((item) => hasRoles(item.allowAccessTo, item.checkRolesStrictly))
  }));
};
