import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { ChangeEvent } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHttpRequest } from "app/hooks";
import { api } from "app/config";
import { Notification } from "./NotificationItem";

type NotificationCountResponse = {
  readonly unreadNotifications: number;
};

const notificationCountAtom = atom<number>(0);

const updateNotificationCountAtom = atom(null, (_, set, updatedCount: number) => {
  set(notificationCountAtom, updatedCount);
});

const searchBarValueAtom = atom<string>("");

const sortDirectionAtom = atom<"asc" | "desc">("desc");

type NotificationFilters = {
  unread: boolean;
  read: boolean;
  info: boolean;
  warning: boolean;
  alert: boolean;
};

const filterLutAtom = atom<NotificationFilters>({ unread: true, read: true, info: true, warning: true, alert: true });

export const notificationsAtom = atom<Notification[]>([]);

export function useNotificationCount() {
  const [notificationCount] = useAtom(notificationCountAtom);
  return notificationCount;
}

export function useUpdateNotificationCount() {
  const updateNotificationCount = useSetAtom(updateNotificationCountAtom);
  const request = useHttpRequest<void, NotificationCountResponse>();

  const { refetch: getNotificationCount } = useQuery(
    ["notification-count"],
    async () => {
      // Replace with call to backend!
      const { data } = await request({ url: `${api}/notifications/count`, method: "GET" }, false);
      return data;
    },
    {
      // enabled: false,
      onSuccess({ unreadNotifications }: NotificationCountResponse) {
        // console.log(`Found ${unreadNotifications} unread notifications`);
        updateNotificationCount(unreadNotifications);
      }
    }
  );

  return getNotificationCount;
}

export function useSortDirection() {
  const [sortDirection, setSortDirection] = useAtom(sortDirectionAtom);
  const changeSortDirection = () => {
    setSortDirection((sortDirection) => (sortDirection === "asc" ? "desc" : "asc"));
  };

  return { sortDirection, changeSortDirection };
}

export function useFilters() {
  const [filters, setFilters] = useAtom(filterLutAtom);
  const changeFilterValue = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { name } = target;
    const key: "unread" | "read" | "info" | "warning" | "alert" = name as
      | "unread"
      | "read"
      | "info"
      | "warning"
      | "alert";
    setFilters((filters) => {
      const updated = { ...filters };
      updated[key] = !updated[key];
      return updated;
    });
  };

  return { filters, changeFilterValue };
}

export function useUpdateSearchQuery() {
  const setSearch = useSetAtom(searchBarValueAtom);
  return (searchValue: string) => setSearch(() => searchValue);
}

function getReadFilter(filters: NotificationFilters, params: Record<string, string>): void {
  const lut: { [key: string]: string } = { read: "true", unread: "false" };
  const keys = Object.entries(filters)
    .filter(([key, value]) => (key === "read" || key === "unread") && value)
    .map(([key]) => key);
  if (keys.length >= 2) return;
  params.read = keys.length === 0 ? "null" : lut[keys[0]];
}

function getTypeFilter(filters: NotificationFilters, params: Record<string, string>): void {
  const keys = Object.entries(filters)
    .filter(([key, value]) => key !== "read" && key !== "unread" && value)
    .map(([key]) => key);
  if (keys.length >= 3) return;
  params.type = keys.length === 0 ? "null" : `:${keys.join(",,")}`;
}

const searchQueryObjectAtom = atom<Record<string, string>>((get) => {
  const sort = get(sortDirectionAtom);
  const filters = get(filterLutAtom);
  const search = get(searchBarValueAtom);

  const object: Record<string, string> = {
    sort: `createdAt,${sort}`
  };

  if (search !== "") {
    object.title = `*${search}*`;
  }

  getReadFilter(filters, object);
  getTypeFilter(filters, object);

  return object;
});

export function useNotificationUrlSearchParams() {
  const searchParams = useAtomValue(searchQueryObjectAtom);
  return searchParams;
}

export function useNotificationReader(reloadNotifications = true) {
  const queryClient = useQueryClient();
  const request = useHttpRequest<void, void>();

  const { mutateAsync: notificationReader } = useMutation(
    async (id?: number) => {
      await request(
        { method: "PUT", url: id ? `${api}/notifications/${id}/read` : `${api}/notifications/read` },
        false
      );
    },
    {
      onSuccess: async () => {
        await queryClient.resetQueries(["notification-count"]);
        if (reloadNotifications) {
          await queryClient.resetQueries(["notifications"]);
        }
      }
    }
  );

  return notificationReader;
}
