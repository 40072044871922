import { useCallback } from "react";
import { useNotificationCount } from "./hooks";

type NotificationCountProps = {
  readonly navItem?: boolean;
  readonly variant?: "default" | "rounded";
};

export const NotificationCount = ({ navItem = false, variant = "rounded" }: NotificationCountProps) => {
  const notificationCount = useNotificationCount();

  const className = `fw-medium badge bg-danger ${
    variant === "rounded" ? "badge rounded-pill" : "d-flex ms-auto align-items-center"
  }`;

  const spanRef = useCallback(
    (span: HTMLSpanElement) => {
      if (!span || !notificationCount || variant === "default") return;
      let x = "-50%";
      let y = "-60%";
      if (notificationCount < 10) {
        x = "-60%";
      } else if (notificationCount > 99) {
        x = "-40%";
      }
      if (navItem) {
        y = "-40%";
      }
      span.style.transform = `translate(${x}, ${y})`;
    },
    [navItem, notificationCount, variant]
  );

  if (notificationCount === 0) return null;
  return (
    <span ref={(ref) => spanRef(ref)} className={className}>
      {notificationCount >= 100 ? "99+" : notificationCount}
      <span className="visually-hidden">unread messages</span>
    </span>
  );
};
