import { downloadBlob, showAlert, Alert } from "app/utils";
import { AxiosRequestConfig, AxiosError } from "axios";
import { useMutation } from "react-query";
import { useHttpRequest } from "./useHttpRequest";
import is from "ramda/src/is";
import { useTranslation } from "react-i18next";

export function useZipFileDownloader(fileName: String) {
  const { t } = useTranslation();
  const request = useHttpRequest<Record<string, any>, ArrayBuffer>();

  const { mutateAsync: downloadZipFile } = useMutation(
    async (config: AxiosRequestConfig) => {
      return await request(config, false);
    },
    {
      onSettled: (response, error) => {
        if (error) return;
        const { data, headers } = response;
        downloadBlob(
          data,
          headers?.["content-type"] ?? "application/zip",
          headers?.["content-disposition"] ?? `${fileName}.zip`
        );
        showAlert({ type: Alert.SUCCESS, message: t("crud.export.data.success") });
      },
      onError: (error: AxiosError<any>) => {
        let json: any = error.response?.data;
        if (is(ArrayBuffer, json)) {
          const decoded = new TextDecoder().decode(json);
          json = JSON.parse(decoded);
        }
        const { detail } = json;
        if (detail) {
          showAlert({ type: Alert.ERROR, message: detail });
        } else {
          showAlert({ type: Alert.ERROR, message: t("crud.export.data.error") });
        }
      }
    }
  );

  return downloadZipFile;
}
