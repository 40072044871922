import differenceInDays from "date-fns/differenceInDays";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import isLeapYear from "date-fns/isLeapYear";
import { Icon } from "app/components/icon";
import { forwardRef, ForwardedRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { notificationsAtom, useNotificationReader } from "./hooks";
import { useSetAtom } from "jotai";
import { getCurrentLocale } from "app/utils";
import { Lang } from "app/i18n";

type NotificationItemProps = {
  readonly notification: Notification;
};

export type Notification = {
  readonly id: number;
  readonly title: string;
  readonly description?: string;
  read: boolean;
  readonly type: "info" | "warning" | "alert";
  readonly createdAt: string;
  readAt: string | null;
};

function formatDate(isoString: string, language: Lang): string {
  const locale = getCurrentLocale(language);
  const date = parseISO(isoString);
  const now = new Date();
  const diff = differenceInDays(now, date);
  const dayInAYear = isLeapYear(date) ? 366 : 365;
  let pattern = "d MMM yy";
  if (diff > dayInAYear) {
    pattern = "d MMM yy p";
  }
  return format(date, pattern, { locale });
}

export const NotificationItem = forwardRef(
  ({ notification }: NotificationItemProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      i18n: { language }
    } = useTranslation();
    const { id, read, type, createdAt } = notification;

    const createdAtFormatted = formatDate(createdAt, language as Lang);

    const iconName =
      type === "info" ? "bi-info-circle" : type === "warning" ? "bi-exclamation-triangle" : "bi-lightning-charge";
    const iconTextColor = type === "info" ? "text-secondary" : type === "warning" ? "text-warning" : "text-danger";

    const markAsRead = useNotificationReader(false);
    const setNotifications = useSetAtom(notificationsAtom);

    const onNotificationItemClick = useCallback(
      (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const { currentTarget } = event;
        currentTarget.querySelector("div.description").classList.toggle("clamped");
        if (!notification.read) {
          markAsRead(notification.id).catch(() => console.error("notification read failed"));
          setNotifications((notifications) => {
            const index = notifications.findIndex((n) => n.id === notification.id);
            if (index !== -1) {
              const cloned: Notification[] = [...notifications];
              cloned[index].read = true;
              return cloned;
            }
            return notifications;
          });
        }
      },
      [notification, markAsRead, setNotifications]
    );

    return (
      <div id={String(id)} className="notification-item" role="button" onClick={onNotificationItemClick} ref={ref}>
        <div className="content">
          <div className="title">
            <p className={read ? "text-secondary" : "fw-bold text-black"}>{notification.title}</p>
          </div>
          <div className="description clamped">
            <p className="text-secondary">{notification?.description ?? ""}</p>
          </div>
        </div>
        <div className="info">
          <Icon name={iconName} className={iconTextColor} />
          <p className="text-secondary">{createdAtFormatted}</p>
        </div>
      </div>
    );
  }
);
