import { Role } from "app/auth";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavItem, NavItemProps } from "./NavItem";
import { Icon } from "../icon";

export type NavItemCollapsableProps = {
  readonly icon?: string;
  readonly label: string;
  readonly allowAccessTo?: Role[];
  readonly checkRolesStrictly?: boolean;
  readonly children: NavItemProps[];
};

export const NavItemCollapsable = ({ icon, label, children }: NavItemCollapsableProps) => {
  const { t } = useTranslation();
  const collapsableRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const toggleShow = () => {
    collapsableRef?.current?.classList?.toggle("show");
    if (collapsableRef?.current?.classList?.contains("show")) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  return (
    <div className="nav-item" role="button">
      <div className="nav-link nav-link-collapsed" role="button" onClick={toggleShow}>
        <div className="d-flex">
          {icon && <Icon name={icon} />}
          <span className="nav-link-label">{t(label)}</span>
        </div>
        <span className="dropdown-nav-toggler">
          {isVisible ? <Icon name="bi-caret-up-fill" /> : <Icon name="bi-caret-down-fill" />}
        </span>
      </div>
      <div className="nav-collapsable collapse" ref={collapsableRef}>
        {children.map((child, index) => (
          <NavItem key={index} {...child} />
        ))}
      </div>
    </div>
  );
};
