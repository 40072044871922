import PerfectScrollbar from "react-perfect-scrollbar";
import { Notification, NotificationItem } from "./NotificationItem";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { useHttpRequest } from "app/hooks";
import { notificationsAtom, useNotificationUrlSearchParams } from "./hooks";
import { api } from "app/config";
import { useCallback, useEffect, useRef } from "react";
import { usePrevious } from "react-use";
import equals from "ramda/src/equals";
import { useAtomValue, useSetAtom } from "jotai";
import { Loading } from "app/components/loading";

type NotificationPage = Readonly<{
  content: Notification[];
  page: Readonly<{
    number: number;
    size: number;
    totalPages: number;
    numberOfElements: number;
    totalElements: number;
  }>;
}>;

type NotificationData = {
  notifications: Notification[];
  nextPage: number;
};

const PAGE_SIZE = 10;
const useNotificationFetcher = () => {
  const request = useHttpRequest<void, NotificationPage>();
  const searchQuery = useNotificationUrlSearchParams();
  const previousSearchQuery = usePrevious(searchQuery);
  const queryClient = useQueryClient();
  const setNotifications = useSetAtom(notificationsAtom);

  const { isLoading, fetchNextPage, hasNextPage, isFetching, refetch } = useInfiniteQuery(
    "notifications",
    async ({ pageParam }): Promise<NotificationData> => {
      const cloned = new URLSearchParams(searchQuery);
      cloned.append("page", pageParam ? pageParam : 0);
      cloned.append("size", String(PAGE_SIZE));

      const { data } = await request(
        {
          method: "GET",
          url: `${api}/notifications`,
          params: cloned
        },
        false
      );

      const { content, page } = data;
      const { number, totalPages } = page;

      return {
        notifications: content,
        nextPage: number <= totalPages - 1 ? number + 1 : null
      };
    },
    {
      onSuccess: (data) => {
        const { pages } = data ?? {};
        setNotifications((pages ?? []).flatMap((item) => item.notifications));
      },
      getNextPageParam: (lastPage, _) => lastPage.nextPage
    }
  );

  useEffect(() => {
    if (previousSearchQuery && !equals(previousSearchQuery, searchQuery)) {
      queryClient.removeQueries("notifications");
      refetch({ refetchPage: (_, index) => index === 0 });
    }
  }, [previousSearchQuery, searchQuery, refetch, queryClient]);

  return { isLoading, fetchNextPage, hasNextPage, isFetching, refetch };
};

export const NotificationList = () => {
  const { isLoading, fetchNextPage, hasNextPage, isFetching } = useNotificationFetcher();
  const observer = useRef<IntersectionObserver>();
  const lastElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetching) {
          fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasNextPage, isFetching, fetchNextPage]
  );

  const notifications = useAtomValue(notificationsAtom);

  return (
    <PerfectScrollbar>
      {(notifications ?? []).map((notification, i) => (
        <NotificationItem
          key={notification.id}
          notification={notification}
          ref={notifications.length === i + 1 ? lastElementRef : null}
        />
      ))}
      {(isLoading || isFetching) && (
        <div>
          <Loading />
        </div>
      )}
    </PerfectScrollbar>
  );
};
