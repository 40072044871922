import { availableLanguages } from "app/config";
import { useTranslation } from "react-i18next";
/**
 * @author Alessandro Frenna
 * @description LanguageSelector to select the current language
 * @returns {JSX.Element}
 */
export const LanguageSelector = (): JSX.Element => {
  const { t, i18n } = useTranslation();

  const onLanguageChange = ({ target: { value } }: any) => {
    i18n.changeLanguage(value);
    window.location.reload();
  };

  return (
    <select className="language-selector" value={i18n.resolvedLanguage} onChange={onLanguageChange}>
      {availableLanguages.map((langObj: Record<string, any>) => {
        return (
          <option key={langObj.language} value={langObj.language} aria-label={t(`languages.${langObj.language}`)}>
            {langObj.code}
          </option>
        );
      })}
    </select>
  );
};
