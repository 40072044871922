export enum LayoutMode {
  FULL = "FULL",
  MINIMIZED = "MINIMIZED",
  MOBILE = "MOBILE"
}

export const getLayoutModeByWindowWidth = (width: number): LayoutMode => {
  if (width <= 1024) {
    return LayoutMode.MOBILE;
  } else if (width > 1024 && width <= 1366) {
    return LayoutMode.MINIMIZED;
  } else {
    return LayoutMode.FULL;
  }
};

export function getSidebarModeByLayout(layout: LayoutMode): "collapsed" | "mini" | "expanded" {
  const modesObject: Record<LayoutMode, "collapsed" | "mini" | "expanded"> = {
    [LayoutMode.MOBILE]: "collapsed",
    [LayoutMode.MINIMIZED]: "mini",
    [LayoutMode.FULL]: "expanded"
  };
  return modesObject[layout];
}
