import { axios } from "app/axios";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { useHistory } from "react-router-dom";

export function useHttpRequest<T, R>() {
  const { push } = useHistory();

  const request = async (
    request: AxiosRequestConfig<T>,
    redirectOnError: boolean = true
  ): Promise<AxiosResponse<R>> => {
    try {
      return await axios(request);
    } catch (error) {
      const axiosError = error as AxiosError<any>;
      if (redirectOnError) {
        const { response } = axiosError;
        const { status } = response;
        push("/error", status);
      }
      if (!redirectOnError) {
        return Promise.reject(axiosError);
      }
    }
  };

  return request;
}
