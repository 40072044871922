import isEmpty from "ramda/src/isEmpty";
import isNil from "ramda/src/isNil";
// import { Library, Loader } from "@googlemaps/js-api-loader";
import { APIProviderProps } from "@vis.gl/react-google-maps";

type DynamicaEnv = "local" | "test" | "development" | "production";

type Endpoints = {
  api: string;
  self: string;
  satApi: string;
  satApiDynamica?: string;
};
// console.debug(process.env);

function getPathsAccordingToEnv(DYNAMICA_ENV: DynamicaEnv): Endpoints {
  const urls: Record<DynamicaEnv, Endpoints> = {
    local: {
      api: "http://localhost:8080/api/v1",
      self: "http://localhost:3000",
      satApi: "http://localhost:8082",
      satApiDynamica: "http://localhost:8083"
    },
    test: {
      api: "https://backend-test.dynamica.cloud/api/v1",
      self: `https://${window.location.host}`,
      satApi: "https://sat-test.dynamica.cloud",
      satApiDynamica: "https://dynsat-test.dynamica.cloud"
    },
    development: {
      api: "https://backend-dev.dynamica.cloud/api/v1",
      self: `https://backoffice-dev.dynamica.cloud`,
      satApi: "https://sat-dev.dynamica.cloud",
      satApiDynamica: "https://dynsat-test.dynamica.cloud"
    },
    production: {
      api: "https://backend.dynamica.cloud/api/v1",
      self: `https://${window.location.host}`,
      satApi: "https://sat.dynamica.cloud",
      satApiDynamica: "https://dynsat.dynamica.cloud"
    }
  };

  return urls[DYNAMICA_ENV];
}

const { api, self, satApi, satApiDynamica } = getPathsAccordingToEnv(process.env.DYNAMICA_ENV as DynamicaEnv);
export { satApi, api, self, satApiDynamica };

export const development = process.env.NODE_ENV === "development";
export const logoSmallColorPng = `${self}/images/logo_small_color.png`;
export const interpolateSvg = `${self}/images/icons/interpolate.svg`;
export const defaultMarker = `${self}/images/icons/icon_psz_01.png`;

export const iotApi = api.concat("/iot");
export const dateFormatter: Record<string, string> = {
  it: "DD/MM/YYYY HH:mm",
  en: "YYYY-MM-DD HH:mm"
};
export enum FileExtension {
  XLSX = "xlsx",
  CSV = "csv",
  CSV_SEMICOLON = "csv;"
}

export const googleMapApiKey = process.env.GMAPS_API_KEY;

export const googleApiProviderProps: APIProviderProps = {
  apiKey: process.env.GMAPS_API_KEY,
  libraries: ["maps", "geometry", "drawing", "places", "geocoding", "marker"],
  region: "IT" // We are in italy
};

export const defaultLanguage = "it";
export const availableLanguages = [
  {
    language: "it",
    formats: {
      date: "DD/MM/YYYY",
      dateTime: "DD/MM/YYYY HH:mm:ss"
    },
    googleLanguage: {
      language: "it-IT",
      region: "IT"
    },
    code: "ITA",
    flagIcon: [0x1f1ee, 0x1f1f9]
  },
  {
    language: "en",
    formats: {
      date: "YYYY-MM-DD",
      dateTime: "YYYY-MM-DD HH:mm:ss"
    },
    googleLanguage: {
      language: "en-US",
      region: "US"
    },
    code: "ENG",
    flagIcon: [0x1f1fa, 0x1f1f8]
  }
];

export const getGoogleLanguageOptions = (lang: string): { language: string; region: string } => {
  const found = availableLanguages.filter((l) => l.language === lang).map((f) => f.googleLanguage);
  if (isNil(found) || isEmpty(found))
    return {
      language: "en-US",
      region: "US"
    };
  else return found[0];
};

export const getFormats = (lang: string): { [key: string]: string } => {
  const found = availableLanguages.filter((l) => l.language === lang).map((f) => f.formats);
  if (isNil(found) || isEmpty(found)) return null;
  else return found[0];
};

export const getIconMapByParam = (param: string): string => {
  const mapper: { [param: string]: string } = {
    default: "icon-icon-crops-white",
    graph: "bi-graph-up",
    "1": "icon-icon-diametrical-growth-of-the-trunk-blue",
    "2": "icon-icon-leaf-wetness-blue",
    "3": "icon-icon-water-ph-blue",
    "4": "icon-icon-soil-water-potential-blue",
    "5": "icon-icon-precipitation-blue",
    "6": "icon-icon-pressure-surface-blue",
    "7": "icon-icon-global-solar-radiation-blue",
    "8": "icon-icon-par-blue",
    "9": "icon-icon-soil-temperature-blue",
    "10": "icon-icon-air-temperature-blue",
    "11": "icon-icon-soil-moisture-blue",
    "12": "icon-icon-air-humidity-blue",
    "13": "icon-icon-surface-wind-speed-blue",
    "14": "icon-icon-surface-wind-direction-blue",
    "15": "icon-icon-electrical-conductivity-of-the-soil-blue",
    "16": "icon-icon-temperature-blue-1",
    "17": "bi-droplet"
  };

  return param in mapper ? mapper[param] : mapper.default;
};

// export function initGoogleMapsApi(lang: string) {
//   const libraries: Library[] = ["maps", "geometry", "drawing", "places", "geocoding", "marker"];
//   const loader = new Loader({
//     apiKey: googleMapApiKey,
//     libraries: ["maps", "geometry", "drawing", "places", "geocoding", "marker"],
//     ...getGoogleLanguageOptions(lang)
//   });
//   libraries.map(
//     async (library: Library) =>
//       await loader.importLibrary(library).then(() => console.debug(`Google Maps: loaded ${library}`))
//   );
//   return loader;
// }

declare global {
  interface Window {
    google: typeof google;
  }
}
