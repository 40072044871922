import { axios } from "app/axios";
import { development } from "app/config";
import Keycloak, { KeycloakInitOptions, KeycloakLoginOptions } from "keycloak-js";
import path from "ramda/src/path";
import Cookies, { CookieSetOptions } from "universal-cookie";

const fetchTenantOptions = async () => {
  const { data } = await axios.get("idp");
  return data;
};

export let keycloak: Keycloak = null;
export const initOptions: KeycloakInitOptions = {
  onLoad: "check-sso",
  pkceMethod: "S256",
  checkLoginIframe: true,
  silentCheckSsoRedirectUri: `${window.origin}/silent-check-sso.html`
};

export const loginOptions: KeycloakLoginOptions = {
  redirectUri: window.origin,
  idpHint: "keycloak"
};

export const getKeycloakInstance = async (): Promise<{ keycloak: Keycloak; hasError: boolean }> => {
  const cookies = new Cookies();
  let hasError = false;
  let realm = cookies.get("IDP");
  let url = cookies.get("IDP_URL");
  const options: CookieSetOptions = {
    httpOnly: false,
    sameSite: "strict",
    secure: !development
  };
  if (!keycloak) {
    if (!realm && !url) {
      try {
        const { issuerName, idpUrl } = await fetchTenantOptions();
        realm = issuerName;
        url = idpUrl;
        cookies.set("IDP", realm, options);
        cookies.set("IDP_URL", url, options);
      } catch (error) {
        console.error(error);
        hasError = true;
      }
    }
    if (!hasError) {
      keycloak = new Keycloak({ clientId: "backoffice-service", realm, url });
    }
  }
  return { keycloak, hasError };
};

export const getKeycloakInstanceProp = (prop: string) => {
  if (!keycloak) throw new Error("keycloak instance is undefined");
  if (prop in keycloak) {
    return path([prop], keycloak);
  } else {
    throw new Error(`property ${prop} does not exist in keycloak instance`);
  }
};
