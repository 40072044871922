import { Alert, extractAlerts, showAlert, showAlerts, WithAlerts } from "app/utils";
import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { useHttpRequest } from "./useHttpRequest";

export function useDeviceCommandScheduler(command: string, successCb?: () => void) {
  const request = useHttpRequest<Record<string, any>, WithAlerts<void>>();
  const { mutateAsync: scheduleCommand } = useMutation(
    async (deviceId: string | number) => {
      const url = `/iot/devices/${deviceId}/commands`;
      const body = { command };
      const { data } = await request({ method: "POST", url, data: body });
      return data;
    },
    {
      onSuccess: (data: WithAlerts<void>) => {
        if (successCb) successCb();
        if (data && "alerts" in data) {
          data = data as WithAlerts<void>;
          showAlerts(extractAlerts(data.alerts));
        }
      },
      onError(error: AxiosError<any>) {
        const { detail } = error.response.data as Record<string, any>;
        if (detail) {
          showAlert({ type: Alert.ERROR, message: detail });
        }
      }
    }
  );

  return { scheduleCommand };
}
