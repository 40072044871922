/**
 * @author Alessandro Frenna
 * @description Ajv configuration
 */
import Ajv2019, { DefinedError, Options } from "ajv/dist/2019";
import draft7MetaSchema from "ajv/dist/refs/json-schema-draft-07.json";
import ajvErrors from "ajv-errors";
import addFormats from "ajv-formats";

export const localizeErrors: Record<string, any> = {
  en: require("ajv-i18n/localize/en"),
  it: require("ajv-i18n/localize/it")
};

export const initAjv = (options: Options): Ajv2019 => {
  const ajv = new Ajv2019({
    ...options,
    allErrors: true,
    removeAdditional: true,
    allowUnionTypes: true,
    useDefaults: true
  });

  ajv.addMetaSchema(draft7MetaSchema);
  ajvErrors(ajv);
  addFormats(ajv);

  return ajv;
};

export const getAjvValidator = <T extends { [key: string]: any }>(schema: T) => {
  const ajv = (window as any).Ajv as Ajv2019;

  const ref = schema["$id"];
  const cachedSchema = ajv.getSchema(ref);

  let validate = cachedSchema;
  if (!cachedSchema) {
    validate = ajv.compile(Object.assign({ $async: false }, schema));
  }

  return validate;
};

export const parseJsonSchemaErrors = <T extends Record<string, unknown>>(
  ajvErrors: DefinedError[],
  reducer: (acc: T, error: DefinedError) => T,
  language?: string
) => {
  // Ajv will return empty instancePath when require error
  if (language) {
    localizeErrors[language](ajvErrors);
  }

  ajvErrors.forEach((error) => {
    if (error.keyword === "required") {
      error.instancePath += "/" + error.params.missingProperty;
    }
  });

  return ajvErrors.reduce(reducer, {} as T);
};

/**
 * Initialize the Ajv validator object
 * @returns {void}
 */
export const attachAjv = (): void => {
  (window as any).Ajv = initAjv({
    coerceTypes: true
  });
  return;
};
