import { NotificationCount } from "app/componentV2/notifications";
import isEmpty from "ramda/src/isEmpty";

type AvatarProps = {
  readonly src: string;
  readonly alt?: string;
};

export const Avatar = ({ src, alt }: AvatarProps) => {
  return (
    <div className="avatar rounded-circle">
      <img src={isEmpty(src) ? "/images/avatar.png" : src} alt={alt} />
      <NotificationCount />
    </div>
  );
};
