import isNil from "ramda/src/isNil";
import { toast } from "react-toastify";

export enum Alert {
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error"
}

export type WithAlerts<T> = {
  readonly result: T;
  readonly alerts: Record<Alert, string>;
};

export const extractAlerts = (alerts?: Record<Alert, string>): { type: Alert; message: string }[] => {
  const notifications: { type: Alert; message: string }[] = [];
  if (!isNil(alerts)) {
    for (const key of Object.keys(alerts)) {
      if (alerts[key as Alert]) {
        notifications.push({ type: key as Alert, message: alerts[key as Alert] });
      }
    }
  }
  return notifications;
};

export const showAlert = (alert: { type: Alert; message: string }) => {
  let openToast: any;
  switch (alert.type) {
    case Alert.ERROR:
      openToast = toast.error;
      break;
    case Alert.WARNING:
      openToast = toast.warning;
      break;
    case Alert.SUCCESS:
      openToast = toast.success;
      break;
    case Alert.INFO:
    default:
      openToast = toast.info;
      break;
  }
  openToast(alert.message);
};

export const showAlerts = (alerts: { type: Alert; message: string }[]) => {
  alerts.forEach(showAlert);
};
