import { Lang, dateLocales } from "app/i18n";
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import parse from "date-fns/parse";
import parseISO from "date-fns/parseISO";

export * from "./alertUtils";
export * from "./fileUtils";

export const getNestedObjectValue = (nestedObj: Record<string, any>, pathArr?: Array<string | number>): unknown => {
  if (!pathArr) return undefined;
  return pathArr.reduce((obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined), nestedObj);
};

export const isBrowser = typeof window !== "undefined";

/*
export function toHectares(sqMeters: number): string {
  const hectares = sqMeters / 10_000;
  const decimalDigits = sqMeters < 75 ? 3 : 2;
  return hectares.toFixed(decimalDigits);
}
*/

export function capitalize(text: string) {
  return text[0].toUpperCase() + text.slice(1);
}

export const generateRandomString = (length: number) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let result = "";

  // Create an array of 32-bit unsigned integers
  const randomValues = new Uint32Array(length);

  // Generate random values
  window.crypto.getRandomValues(randomValues);
  randomValues.forEach((value) => {
    result += characters.charAt(value % charactersLength);
  });
  return result;
};

export const getCurrentLocale = (lang: Lang): Locale => {
  return dateLocales[lang];
};

export function parseDateString(dateString: string): Date {
  if (!dateString || dateString === "") return null;
  return parse(dateString, "yyyy-MM-dd", new Date());
}

export function formatDateWithPattern(date: Date, language: Lang, pattern: string): string {
  if (date == null) return "";
  const locale = getCurrentLocale(language);
  return format(date, pattern, { locale });
}

export function formatDate(date: Date, language: Lang): string {
  return formatDateWithPattern(date, language, "P");
}

export function parseAndFormatDateString(dateString: string, language: Lang): string {
  const imageDate = parseDateString(dateString);
  return formatDate(imageDate, language);
}

export function parseAndFormatISOString(isoString: string, language: Lang, pattern = "Pp"): string {
  if (!isoString || isoString === "") return "";
  const locale = getCurrentLocale(language);
  const date = parseISO(isoString);
  return format(date, pattern, { locale });
}

export function parseDateStringWithPattern(dateString: string, language: Lang, pattern: string): Date {
  if (!dateString || dateString === "") return null;
  const locale = getCurrentLocale(language);
  return parse(dateString, pattern, new Date(), { locale });
}

export function parseDate(value: string | Date, onlyDate: boolean, onlyTime: boolean, language: Lang) {
  let date: Date = null;
  // console.log(value);
  if (typeof value === "string") {
    if (onlyDate) {
      // console.log("Trying only date");
      date = parseDateStringWithPattern(value, language, "yyyy-MM-dd");
    } else {
      // console.log("Trying ISO");
      date = parseISO(value);
    }
  } else {
    date = value;
  }
  return isValid(date) ? date : null;
}

export function formatTime(date: Date, language: Lang): string {
  if (date == null) return "";
  const locale = getCurrentLocale(language);
  return format(date, "p", { locale });
}
