import { Role } from "app/auth";
import { NavItem, NavItemProps } from "./NavItem";
import { NavItemCollapsable, NavItemCollapsableProps } from "./NavItemCollapsable";
import { NavLabel } from "./NavLabel";

export type VerticalNavProps = {
  readonly label?: string;
  readonly items: (NavItemProps | NavItemCollapsableProps)[];
  readonly allowAccessTo?: Role[];
  readonly checkRolesStrictly?: boolean;
};

export const VerticalNav = ({ label, items }: VerticalNavProps) => {
  return (
    <nav className="nav" role="navigation">
      <NavLabel label={label} />
      {items.map((item, index) => {
        if ("children" in item) {
          return <NavItemCollapsable key={index} {...item} />;
        } else {
          return <NavItem key={index} {...item} />;
        }
      })}
    </nav>
  );
};
