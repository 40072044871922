import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

type DeviceMarkerInfoWindowProps = Readonly<{
  device: string;
  formattedAddress: string;
  devicePageLink: string;
}>;

export const DeviceMarkerInfoWindow = (props: DeviceMarkerInfoWindowProps) => {
  const { device, devicePageLink, formattedAddress } = props;

  const { t } = useTranslation();
  return (
    <div>
      <h4>{device}</h4>
      <p>{formattedAddress}</p>
      <Button as="a" href={devicePageLink} size="sm" variant="success" className="w-100">
        {t("devices.showButtonLabel", { device })}
      </Button>
    </div>
  );
};
