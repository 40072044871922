import KeycloakInstance from "keycloak-js";

export enum Role {
  ALL = "*",
  SUPERADMIN = "SUPERADMIN",
  ADMIN = "ADMIN",
  MANAGER = "MANAGER",
  VIEWER = "VIEWER"
}

export type Tenant = string;

export const hasRoles =
  (user: Record<string, any>, keycloak: KeycloakInstance) =>
  (roles: Role[], all: boolean = true) => {
    if (!roles || !user) return true;
    let isSuperAdmin = false;
    if (roles.includes(Role.SUPERADMIN)) {
      const { superAdmin } = user;
      isSuperAdmin = superAdmin;
      if (all) {
        return isSuperAdmin;
      }
    }

    if (all) {
      if (roles.includes(Role.ALL)) return true;
      else {
        return roles.every((role: Role) => keycloak.hasRealmRole(role.toLowerCase()));
      }
    } else {
      return (
        isSuperAdmin ||
        roles.includes(Role.ALL) ||
        roles.some((role: Role) => keycloak.hasRealmRole(role.toLowerCase()))
      );
    }
  };

export const ofTenants = (user: Record<string, any>) => {
  return (tenants: Tenant | Tenant[]) => {
    const { tenant } = user;
    const { name } = tenant;

    // console.log("Allowed tenants:", tenants, "\nUser tenant:", name);
    if (Array.isArray(tenants) && tenants.length > 0) {
      return tenants.includes(name);
    }

    if (tenants as Tenant) {
      return (tenants as Tenant) === name;
    }

    return false;
  };
};
