import { Icon } from "app/components/icon";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

export const Error = () => {
  const { t } = useTranslation();
  const { state } = useLocation<number>();

  return (
    <div className="container-fluid d-flex flex-column gap-3 align-items-center justify-content-center p-0">
      <div className="text-center">
        <h1 className="p-0 m-0">{t(`errors.${state === 404 ? "404" : "global"}`)}</h1>
      </div>
      <div className="d-flex flex-row gap-4 align-item-center justify-content-center">
        <Link to="/" className="btn btn-lg btn-success" replace>
          <Icon name="icon-icon-dashboard-white" /> {t("goHome")}
        </Link>
      </div>
    </div>
  );
};

export default Error;
