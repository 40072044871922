import clsx from "clsx";
import { Link } from "react-router-dom";

export type LogoProps = {
  readonly src: string;
  readonly text?: {
    readonly content: string;
    readonly className?: string;
  };
  readonly className?: string;
  readonly width?: number;
  readonly height?: number;
  readonly children?: React.ReactChild;
};

export const Logo = (props: LogoProps) => {
  const { children, src, text, className } = props;

  return (
    <Link to="/" className="navbar-brand">
      {children ? (
        { children }
      ) : (
        <>
          <img className={className} src={src} alt={text?.content ?? "Company"} />
          {text && <span className={clsx("ms-2 fw-normal", className)}>{text.content.toUpperCase()}</span>}
        </>
      )}
    </Link>
  );
};
