import { createContext, useCallback, useLayoutEffect, useState } from "react";
import { getLayoutModeByWindowWidth, getSidebarModeByLayout, LayoutMode } from "./commons";
import { useWindowSize } from "react-use";

export type LayoutContexProps = {
  readonly children: React.ReactNode;
};

export type LayoutContextValues = {
  readonly sidebarMode?: "collapsed" | "mini" | "expanded";
  readonly toggleSidebar: () => void;
  readonly layoutMode: LayoutMode;
};

export const LayoutContext = createContext<LayoutContextValues>({
  toggleSidebar: () => {},
  layoutMode: LayoutMode.FULL
});

export const LayoutProvider = (props: LayoutContexProps) => {
  const [layoutMode, setLayoutMode] = useState<LayoutMode>();
  const [sidebarMode, updateSidebarMode] = useState<"collapsed" | "mini" | "expanded">("collapsed");
  const [isExplicitlyMini, updateMiniState] = useState(false);

  const toggleSidebar = useCallback(() => {
    // console.log("LayoutMode", layoutMode, "\nSidebarMode", sidebarMode);
    if (LayoutMode.MOBILE === layoutMode) {
      updateMiniState(false);
      if (sidebarMode === "collapsed") {
        updateSidebarMode("expanded");
      } else if (sidebarMode === "expanded") {
        updateSidebarMode("collapsed");
      }
    }

    if (LayoutMode.MINIMIZED === layoutMode) {
      if (sidebarMode === "mini") {
        updateMiniState(false);
        updateSidebarMode("expanded");
      } else if (sidebarMode === "expanded") {
        updateMiniState(true);
        updateSidebarMode("mini");
      }
    }

    if (LayoutMode.FULL === layoutMode) {
      if (sidebarMode === "mini") {
        updateMiniState(false);
        updateSidebarMode("expanded");
      } else if (sidebarMode === "expanded") {
        updateMiniState(true);
        updateSidebarMode("mini");
      }
    }
  }, [layoutMode, sidebarMode]);

  const { width } = useWindowSize();
  useLayoutEffect(() => {
    const layoutMode = getLayoutModeByWindowWidth(width);
    const sidebarMode = getSidebarModeByLayout(layoutMode);

    // console.log(width, layoutMode, isExplicitlyMini);
    if (LayoutMode.MOBILE === layoutMode || !isExplicitlyMini) {
      isExplicitlyMini && updateMiniState(false); // isExplicitlyMini could be true
      updateSidebarMode(sidebarMode);
    }

    setLayoutMode(layoutMode);
  }, [width, isExplicitlyMini]);

  return (
    <LayoutContext.Provider
      value={{
        toggleSidebar,
        layoutMode,
        sidebarMode
      }}
    >
      {props.children}
    </LayoutContext.Provider>
  );
};
