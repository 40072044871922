import { ReactKeycloakProvider } from "@react-keycloak/web";
import { APIProvider } from "@vis.gl/react-google-maps";
import { Authenticated, initOptions, getKeycloakInstance } from "app/auth";
import { GlobalLoading } from "app/components/loading";
import "app/i18n";
import { DynamicaLayout } from "app/layouts";
import { getAllowedNavigationPaths, Routes } from "app/routing";
import { Provider } from "jotai";
import { queryClientAtom } from "jotai-tanstack-query";
import { useHydrateAtoms } from "jotai/utils";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { NotificationProvider } from "./componentV2/notifications";
import { googleApiProviderProps } from "./config";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, notifyOnChangeProps: "tracked" } }
});

const HydrateAtoms = ({ children }: PropsWithChildren<{}>) => {
  useHydrateAtoms([[queryClientAtom, queryClient as any]]);
  return <>{children}</>;
};

const { keycloak, hasError } = await getKeycloakInstance();
export const App = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  if (hasError) {
    return (
      <div className="d-flex vh-100 container-fluid justify-content-center align-items-center">
        <h1>{t("errors.global")}</h1>
      </div>
    );
  }
  return (
    <ReactKeycloakProvider
      autoRefreshToken={true}
      authClient={keycloak}
      initOptions={initOptions}
      // onTokens={() => console.info("TOKENS UPDATED:", new Date().toLocaleString())}
      LoadingComponent={<GlobalLoading />}
    >
      <QueryClientProvider client={queryClient}>
        <Provider>
          <HydrateAtoms>
            <APIProvider {...googleApiProviderProps} language={language}>
              <Router>
                <Authenticated>
                  {({ hasRoles }) => {
                    const menus = getAllowedNavigationPaths(hasRoles);
                    return (
                      <NotificationProvider>
                        <DynamicaLayout sidebarMenus={menus}>
                          <Routes />
                        </DynamicaLayout>
                        <ToastContainer
                          closeOnClick
                          autoClose={4000}
                          position="bottom-right"
                          hideProgressBar={false}
                          pauseOnFocusLoss={false}
                          pauseOnHover={false}
                          className="toast-alert"
                        />
                      </NotificationProvider>
                    );
                  }}
                </Authenticated>
              </Router>
            </APIProvider>
          </HydrateAtoms>
        </Provider>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
      </QueryClientProvider>
    </ReactKeycloakProvider>
  );
};

// Required base class
class SlElement {
  /*
   * @example
   *   static reactEvents = {
   *     "onSlChange": CustomEvent<{}>
   *   }
   */
  static reactEvents: Record<string, Event>;
}

type ReactEvents<TEvents extends Record<string, Event>> = {
  [P in keyof TEvents]?: (e: TEvents[P]) => void;
};

type CustomElement<T extends typeof SlElement> = Partial<
  InstanceType<T> & { children: any } & ReactEvents<T["reactEvents"]>
>;

declare global {
  namespace react.createElement.JSX {
    interface IntrinsicElements {
      ["swiper-container"]: CustomElement<any>;
      ["swiper-slide"]: CustomElement<any>;
    }
  }
}
