import { LayoutProvider } from "./context";
import { Content } from "./Content";
import { Sidebar, SidebarMenus } from "./Sidebar";
import { Suspense } from "react";
import { Loading } from "app/components/loading";

export type LayoutProps = {
  readonly sidebarMenus: SidebarMenus;
  readonly children: React.ReactChild;
};

export const DynamicaLayout = ({ children, sidebarMenus }: LayoutProps) => {
  return (
    <LayoutProvider>
      <Sidebar menus={sidebarMenus} />
      <Content>
        <Suspense fallback={<Loading />}>{children}</Suspense>
      </Content>
    </LayoutProvider>
  );
};
