import { attachAjv } from "app/ajv";
import { App } from "app/App";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import "app/assets/styles/style.scss";
import "bootstrap/dist/js/bootstrap.min.js";

attachAjv();

ReactDOM.render(<App />, document.getElementById("root"));

reportWebVitals();
