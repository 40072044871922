/**
 * @author Alessandro Frenna
 * @description Component that shows the user badge in the topbar
 */
import { Role, useAuthenticated } from "app/auth";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Dropdown from "react-bootstrap/Dropdown";
import { Avatar } from "./Avatar";
import { Logout } from "./Logout";
import { Icon } from "../icon";
import { NotificationCount } from "app/componentV2/notifications";

export * from "./Avatar";

export const AuthUserInfo = () => {
  const { t } = useTranslation();
  const { user, goToProfileManagementPage, hasRoles } = useAuthenticated();
  const history = useHistory();

  return (
    <Dropdown role="button" align="start" className="userinfo">
      <Dropdown.Toggle as="span" className="rounded-pill">
        <div className="d-flex flex-row justify-content-between align-items-center gap-lg-2 py-1 px-1 rounded-pill">
          {user?.fullName && (
            <div className="username">
              <p className="text-center m-0">{user.fullName}</p>
            </div>
          )}
          <Avatar src={user?.picture ?? ""} />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu rootCloseEvent="click" variant="secondary">
        <Dropdown.Item onClick={() => history.push("/notifications")}>
          <p className="m-0 text-left">
            <Icon name="bi-bell" /> {t("topbar.profile.items.notifications")} <NotificationCount variant="default" />
          </p>
        </Dropdown.Item>
        {hasRoles([Role.SUPERADMIN, Role.ADMIN, Role.MANAGER], false) && (
          <Dropdown.Item onClick={goToProfileManagementPage}>
            <p className="m-0 text-left">
              <Icon name="icon-icon-name-login-green" /> {t("topbar.profile.items.profile")}
            </p>
          </Dropdown.Item>
        )}
        <Dropdown.Item onClick={() => history.push("/profile/change-password")}>
          <p className="m-0 text-left">
            <Icon name="bi-key" /> {t("topbar.profile.items.password")}
          </p>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item>
          <Logout iconLeft className="text-center" />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
