/**
 * @author Alessandro Frenna
 * @description private route component
 */
import { useKeycloak } from "@react-keycloak/web";
import { Role, useAuthenticated } from "app/auth";
import isNil from "ramda/src/isNil";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import type { RouteProps } from "react-router-dom";

interface PrivateRouteProps extends RouteProps {
  readonly allowAccessTo: Role[];
  readonly checkRolesStrictly?: boolean;
  readonly component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

// Examples used:
// https://github.com/react-keycloak/react-keycloak-examples/blob/9fd726646e6358b9b3ca7c82348ce0e23bc87f0b/examples/react-router/src/routes/utils.tsx#L13
export const PrivateRoute = ({
  component: Component,
  allowAccessTo,
  checkRolesStrictly,
  ...rest
}: PrivateRouteProps) => {
  const { keycloak } = useKeycloak();
  const { hasRoles, login } = useAuthenticated();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!keycloak.authenticated) {
          login();
          return null;
        }
        if (keycloak?.token ?? null) {
          if (keycloak.isTokenExpired()) {
            keycloak.updateToken(0).catch(() => login());
          }
          if (hasRoles(allowAccessTo, isNil(checkRolesStrictly) ? false : checkRolesStrictly))
            return <Component {...props} />;
          else return <Redirect to={{ pathname: "/error", state: 404 }} />;
        } else {
          login();
          return null;
        }
      }}
    />
  );
};
