import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";

type LoadingProps = {
  readonly variant?: "primary" | "secondary";
  readonly animation?: "grow" | "border";
};

export const ColoredSpinner = ({ variant, animation }: LoadingProps) => {
  const { t } = useTranslation();

  return (
    <Spinner animation={animation ?? "border"} variant={variant} role="status">
      <span className="visually-hidden">{t("loading")}</span>
    </Spinner>
  );
};

export const Loading = ({ animation }: LoadingProps) => {
  return (
    <div className="container-fluid d-flex align-items-center justify-content-center">
      <ColoredSpinner variant="secondary" animation={animation} />
    </div>
  );
};

export const GlobalLoading = () => {
  return (
    <main className="loading">
      <div className="wrapper">
        <Loading />
      </div>
    </main>
  );
};
