import { DeviceMarkerInfoWindow } from "app/componentV2/DeviceMarkersInfoWindow";
import isNil from "ramda/src/isNil";
import { createElement } from "react";

type Options = {
  withInfoWindow: boolean;
  clickable: boolean;
};

const defaultOptions: Options = {
  withInfoWindow: false,
  clickable: false
};

export function useMakeDevicesMarkers() {
  return (devices: Record<string, any>[], options = defaultOptions) => {
    if (devices.length === 0) return [];
    const { withInfoWindow, clickable } = options;
    return devices
      .filter((device) => !isNil(device?.lastKnownLocation))
      .map((device) => {
        const { id, name, address } = device ?? { name: "", address: {} };
        const { formatted } = address ?? { formatted: "" };
        const { coordinates } = device.lastKnownLocation;
        const [lng, lat] = coordinates;
        return {
          title: name,
          clickable: withInfoWindow || clickable,
          draggable: false,
          position: { lng, lat },
          infoWindowContent: withInfoWindow
            ? createElement(DeviceMarkerInfoWindow, {
                device: name,
                formattedAddress: formatted,
                devicePageLink: `/devices/${id}`
              })
            : null
        };
      });
  };
}
