import clsx from "clsx";
import isEmpty from "ramda/src/isEmpty";
import isNil from "ramda/src/isNil";

export type IconProps = {
  readonly name?: string;
  readonly src?: string;
  readonly alt?: string;
  readonly title?: string;
  readonly width?: number;
  readonly height?: number;
  readonly onClick?: React.MouseEventHandler<unknown>;
  readonly className?: string;
};

export const Icon = ({ name, src, ...props }: IconProps) => {
  if (isNil(name) || isEmpty(name)) {
    return (
      <img
        src={src}
        width={props?.width ?? 20}
        height={props?.height ?? 20}
        alt={props?.alt}
        title={props?.title}
        onClick={props?.onClick}
        className={props?.className}
      />
    );
  } else {
    return <i className={clsx(props.className, name)} onClick={props?.onClick} />;
  }
};
