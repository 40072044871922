/**
 * @author Alessandro Frenna
 * @description logout text-button component
 */
import { useAuthenticated } from "app/auth";
import { useTranslation } from "react-i18next";
import { Icon } from "../icon";

type LogoutProps = {
  readonly iconLeft?: boolean;
  readonly className?: string;
};

/**
 * The logout component
 * @param {LogoutProps} props
 * @returns {JSX.Element}
 */
export const Logout = ({ iconLeft, className }: LogoutProps): JSX.Element => {
  const { t } = useTranslation();
  const { logout } = useAuthenticated();
  return (
    <div role="button" className="logout d-flex" onClick={logout}>
      {iconLeft ? (
        <>
          <Icon name="bi-box-arrow-left" />
          <span className="ms-1 footer-label">{t("logout")}</span>
        </>
      ) : (
        <>
          <span className="footer-label me-1">{t("logout")}</span>
          <Icon name="bi-box-arrow-left" />
        </>
      )}
    </div>
  );
};
