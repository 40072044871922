import { LanguageSelector } from "app/components/language";
import { Logo } from "app/components/logo";
import { VerticalNav, VerticalNavProps } from "app/components/navs";
import { useContext } from "react";
import { LayoutContext } from "./context";
import { LayoutMode } from "./context/commons";
import PerfectScrollbar from "react-perfect-scrollbar";

export type SidebarMenus = VerticalNavProps[];

type SidebarProps = {
  readonly menus: SidebarMenus;
};

export const Sidebar = ({ menus }: SidebarProps) => {
  const { layoutMode, toggleSidebar, sidebarMode } = useContext(LayoutContext);

  return (
    <nav className={`sidebar ${sidebarMode}`}>
      <header>
        <Logo
          className="brand"
          src={sidebarMode === "mini" ? "/images/logo_dynamica.svg" : "/images/logo_dynamica_full.svg"}
          text={{ content: "" }}
        />
        {layoutMode === LayoutMode.MOBILE && (
          <div className="controls">
            <LanguageSelector />
            <span
              role="button"
              className="d-inline-flex btn-close btn-close-white"
              aria-label="Close"
              onClick={toggleSidebar}
            />
          </div>
        )}
      </header>

      <div className="menus">
        <PerfectScrollbar>
          {menus.map((menu, index) => (
            <VerticalNav key={index} {...menu} />
          ))}
        </PerfectScrollbar>
      </div>
    </nav>
  );
};
