import { Role } from "app/auth";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Icon } from "../icon";
import { LayoutContext } from "app/layouts/context";
import { LayoutMode } from "app/layouts/context/commons";
import { useContext, useCallback, ReactNode } from "react";

export type NavItemProps = {
  readonly icon?: string;
  readonly label: string;
  readonly path: string;
  readonly allowAccessTo?: Role[];
  readonly checkRolesStrictly?: boolean;
  readonly decorateIconWith?: ReactNode;
};

export const NavItem = ({ icon, label, path, decorateIconWith }: NavItemProps) => {
  const { t } = useTranslation();
  const translatedLabel = t(label);
  const { toggleSidebar, layoutMode } = useContext(LayoutContext);

  const maybeToggleSidebar = useCallback(() => {
    if (LayoutMode.MOBILE === layoutMode) {
      toggleSidebar();
    }
  }, [layoutMode, toggleSidebar]);

  return (
    <div className="nav-item" role="button">
      <NavLink
        to={path}
        activeClassName="active"
        className="nav-link"
        title={translatedLabel}
        onClick={maybeToggleSidebar}
      >
        {icon && (
          <>
            {decorateIconWith ? (
              <div className="decorated-icon-container">
                <Icon name={icon} />
                {decorateIconWith}
              </div>
            ) : (
              <Icon name={icon} />
            )}
          </>
        )}
        <span className="nav-link-label">{translatedLabel}</span>
      </NavLink>
    </div>
  );
};
