import { LanguageSelector } from "app/components/language";
import { Logo } from "app/components/logo";
import { AuthUserInfo } from "app/components/user";
import { useContext } from "react";
import { LayoutContext } from "./context";
import { Icon } from "app/components/icon";

export const Topbar = () => {
  const { toggleSidebar, sidebarMode } = useContext(LayoutContext);
  return (
    <header className="topbar navbar-light">
      <div className="d-flex align-items-center">
        <div className="burger-container">
          <Icon
            name="icon-icon-hamburger-green "
            className="burger"
            title="toggle lateral sidebar"
            onClick={toggleSidebar}
          />
        </div>
        <LanguageSelector />
      </div>
      {sidebarMode === "collapsed" && <Logo src="/images/topbar_logo.svg" text={{ content: "Dynamica" }} />}
      <AuthUserInfo />
    </header>
  );
};
