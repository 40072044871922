import { PropsWithChildren, createContext, useLayoutEffect } from "react";
import { useUpdateNotificationCount } from "./hooks";

const NotificationContext = createContext<void>(null);

export const NotificationProvider = ({ children }: PropsWithChildren<{}>) => {
  const getNotificationCount = useUpdateNotificationCount();

  useLayoutEffect(() => {
    // Get notification count from the backend
    // getNotificationCount();
    // update notification count every 300 seconds
    const interval = setInterval(getNotificationCount, 300 * 1000);
    // return callback must clear interval
    return () => clearInterval(interval);
  }, [getNotificationCount]);

  return <NotificationContext.Provider value={null}>{children}</NotificationContext.Provider>;
};

export * from "./MarkAllAsReadButton";
export * from "./NotificationCount";
export * from "./NotificationList";
export * from "./NotificationsSettings";
