import { useTranslation } from "react-i18next";

export type NavLabelProps = {
  readonly label?: string;
};

export const NavLabel = ({ label }: NavLabelProps) => {
  const { t } = useTranslation();
  return label ? (
    <div className="nav-item">
      <div className="nav-label">
        <span className="nav-label-bullet">--</span>
        <span className="nav-label-text">{t(label).toUpperCase()}</span>
      </div>
    </div>
  ) : null;
};
